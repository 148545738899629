.inputHorizontal {
  height: 0.5rem;
}

.inputVertical {
  width: 0.5rem;
}

.track {
  border-color: #60718d;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.highlight {
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.highlightHorizontal {
  background: linear-gradient(
    180deg,
    #637491,
    #637491 21%,
    #637491 38%,
    #3c4b66 68%,
    #637491
  );
}

.highlightVertical {
  background: linear-gradient(
    90deg,
    #637491,
    #637491 21%,
    #637491 38%,
    #3c4b66 68%,
    #637491
  );
}

.handleHorizontal {
  width: 30px;
  height: 1rem;
}

.handleVertical {
  width: 1rem;
  height: 30px;
}
