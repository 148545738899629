.numbers-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.numbers-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.numbers-exit {
  opacity: 1;
}
.numbers-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 500ms, transform 500ms;
}

.stores-enter {
  opacity: 0;
  transform: translateY(0%);
}
.stores-enter-active {
  opacity: 1;
  transform: translateY(-90%);
  transition: opacity 500ms, transform 500ms;
}
.stores-exit {
  opacity: 1;
  transform: translateY(-90%);
}
.stores-exit-active {
  opacity: 0;
  transform: translateY(0%);
  transition: opacity 500ms, transform 500ms;
}
