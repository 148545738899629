.lobby {
  background: rgb(13, 63, 100);
  background: radial-gradient(
    circle,
    rgba(13, 63, 100, 1) 0%,
    rgba(0, 46, 72, 1) 100%
  );
}

.helpButton {
  margin-top: -3px;
}

.rightHeaderIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #262f40;
  border: 1px solid #2a313c;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 8px;
}

.rightHeaderIcon:hover {
  background: #32435d;
}

.profileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-right: 8px;
  .profileImage {
    border-radius: 6px 0px 0px 6px;
  }
  .profileName {
    color: white;
    font-family: "PoppinsSemiBold";
    font-size: 12px;
    line-height: 17px;
    padding: 0px 10px;
    font-weight: 600;
  }
}

.profileButton {
  background: #262f40;
  border: 1px solid #2a313c;
  cursor: pointer;
}
.profileButton:hover {
  background: #32435d;
}

.registerButton {
  background: #0068ff;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  color: white;
  font-family: "PoppinsMedium";
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  margin-right: 8px;
  border-radius: 6px;
}

.avatarMenu {
  right: -40px;
  top: 46px;
}

.mainScreen {
  display: flex;
  padding: 16px 16px;

  @media screen and (max-width: 1239px) {
    flex-direction: column;
    width: 794px;
    margin: 0 auto;
    padding: 0;
  }

  @media screen and (max-width: 833px) {
    width: 343px;
  }
}

.newsImageSectionWrapper {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  border: 1px solid #3b4554;
  border-radius: 8px;
  padding: 32px 24px;

  @media screen and (max-width: 1239px) {
    margin: 40px 0;
    background: unset !important;
    padding: 0;
    border: none;
  }

  @media screen and (max-width: 833px) {
    margin: 30px 0;
  }
}

.newsTitle {
  font-family: PoppinsSemiBold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 20px;
  color: #ffffff;

  @media screen and (max-width: 1239px) {
    text-align: left;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
  }
}

.roomsTitle {
  font-family: PoppinsSemiBold;
  color: #ffffff;
  display: none;

  @media screen and (max-width: 1239px) {
    display: block;
    text-align: left;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.imageWrapper {
  overflow: hidden;
  position: relative;
  height: 100%;

  @media screen and (max-width: 1239px) {
    height: 250px;
    margin-top: 16px;
  }

  @media screen and (max-width: 833px) {
    height: 200px;
  }
}
.roomsWrapper {
  width: 75%;

  @media screen and (max-width: 1239px) {
    overflow: visible;
    width: 100%;
  }
}

.newsWrapper {
  width: 25%;

  @media screen and (max-width: 1239px) {
    width: 100%;
    position: relative;
  }
}

.roomsDataWrapper {
  position: relative;
  margin-left: 28px;
  overflow: "visible";

  @media screen and (max-width: 1239px) {
    margin-left: unset;
  }
}

.roomInfoContainer {
  bottom: 0;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  font-family: "PoppinsMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.01em;
  min-height: 28px;
}

.roomInfoRightContainer {
  min-width: 60px;
}

.roomUserCounter {
  margin-left: 22px;
}

.roomInfoName {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.roomInfoNumbers {
  padding-left: 4px;
  color: #cfcfcf;
}

.roomTableCounterImage {
  width: 12px;
  height: 12px;
}

.roomUserCounterImage {
  width: 10px;
  height: 11px;
}

.storeIcon {
  display: flex;
  width: 24px;
  height: 24px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  gap: 7.123px;
  border-radius: 71.231px;
  border: 0.712px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0.7123104333877563px 0.7123104333877563px 1.4246208667755127px 0px
    rgba(16, 24, 40, 0.1);
  cursor: pointer;
}
.sectionStoreIcon {
  display: flex;
  width: 32px;
  height: 32px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  gap: 7.123px;
  border-radius: 71.231px;
  border: 0.712px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0.7123104333877563px 0.7123104333877563px 1.4246208667755127px 0px
    rgba(16, 24, 40, 0.1);
  cursor: pointer;
}

.roomImage {
  @media screen and (max-width: 1239px) {
    width: 100%;
    object-fit: contain !important;
  }
}

.roomImageGradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 68.79%,
    rgba(0, 0, 0, 0.83) 115.86%
  );
  border-radius: 4px;
}

.prevArrow {
  position: absolute;
  width: 32px;
  height: 32px;
  left: -1%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  cursor: pointer;
}

.nextArrow {
  position: absolute;
  width: 32px;
  height: 32px;
  right: -1%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  cursor: pointer;
}

.prevSectionArrow {
  position: absolute;
  z-index: 6;
  width: 32px;
  height: 32px;
  left: -6%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  cursor: pointer;
}

.nextSectionArrow {
  position: absolute;
  z-index: 6;
  width: 32px;
  height: 32px;
  right: -6%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  cursor: pointer;
}

.previousSection {
  filter: blur(1px);
  position: relative;
  transform: rotate(359deg);
}

.nextSection {
  filter: blur(1px);
  position: relative;
  transform: rotate(1deg);
}

.currentSection {
  position: relative;
  z-index: 5;
}

.sections {
  position: absolute;
  transform-style: preserve-3d;
}

.customDot {
  width: "6px";
  height: "6px";
  border-radius: "6px";
  border: "1px solid white";
  padding: 4;
}

.dotsContainer {
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
  display: flex;
}

.sectionInnerContainer {
  position: absolute;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  z-index: 6;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.playTextContainer {
  min-height: 32px;
  border-radius: 3.629px;
  background: #fff;
  box-shadow: 1.134147047996521px 1.134147047996521px 5.6707353591918945px 0px
    rgba(16, 24, 40, 0.1);
  padding: 2px 20px;
  cursor: pointer;
}

.playText {
  position: relative;
  transform: translateY(30%);
  color: #000;
  font-size: 14px;
  font-family: PoppinsMedium;
  font-style: normal;
  font-weight: 500;

  @media screen and (max-width: 1239px) {
    font-size: 16px;
    transform: translateY(10%);
  }
}
