/* purgecss start ignore */
:root {
  --reach-listbox: 1;
}

[data-reach-listbox-popover] {
  display: block;
  position: absolute;
  min-width: min-content;
  padding: 0.25rem 0;
  background: hsl(0, 0%, 100%);
  outline: none;
  border: solid 1px hsla(0, 0%, 0%, 0.25);
}

[data-reach-listbox-popover][hidden] {
  display: none;
}

[data-reach-listbox-list] {
  margin: 0;
  padding: 0;
  list-style: none;
}

[data-reach-listbox-list]:focus {
  box-shadow: none;
  outline: none;
}

[data-reach-listbox-option] {
  display: block;
  text-align: center;
  margin: 0;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  user-select: none;
}

[data-reach-listbox-option][aria-selected="true"] {
  font-weight: bolder;
  cursor: pointer;
}

[data-reach-listbox-option][data-current] {
  font-weight: bolder;
}

[data-reach-listbox-option][data-current][data-confirming] {
  animation: flash 100ms;
  animation-iteration-count: 1;
}

[data-reach-listbox-option][aria-disabled="true"] {
  opacity: 0.5;
}

[data-reach-listbox-button] {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 10px 2px;
  border: 1px solid;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  cursor: default;
  user-select: none;
}

[data-reach-listbox-button][aria-disabled="true"] {
  opacity: 0.5;
}

[data-reach-listbox-arrow] {
  margin-left: 0.5rem;
  display: block;
  font-size: 0.5em;
}

[data-reach-listbox-group-label] {
  display: block;
  margin: 0;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  user-select: none;
  font-weight: bolder;
}

@keyframes flash {
  0% {
    background: hsla(211, 81%, 36%, 1);
    color: hsl(0, 0%, 100%);
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    background: inherit;
    color: inherit;
  }
  100% {
    background: hsla(211, 81%, 36%, 1);
    color: hsl(0, 0%, 100%);
    opacity: 1;
  }
}

/* purgecss end ignore */
