.header {
  background: #1b1f28;
  padding: 0 16px;
}

.headerAction {
  padding: 2px 5px;
  transform: translate(25%, 25%);
}

.headerLogo {
  margin-right: 20px;
}

.leftHeaderButton {
  margin-right: 15px;
}

.flag {
  position: absolute;
  top: -30%;
  right: 10%;
}
