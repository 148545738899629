.RegisterWindow {
  width: 386px;
  height: 289px;
  background: url("register_window.png");
}

.RegisterWindowText {
  position: relative;
  top: 65px;
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #16419e;
}

.RegisterButton {
  position: relative;
  top: 100px;
  left: 149px;
  width: 87px;
  height: 41px;
  background: url("register_button.png");
}

.RegisterText {
  position: relative;
  top: 5px;
  left: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
}

.CloseRegisterPopup {
  position: relative;
  top: 4px;
  left: 347px;
  width: 30px;
  height: 36px;
  background: url("close_register_popup.png");
}
