.scrollable {
  scrollbar-color: #1f5fa3 #073959;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: #073959;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #073959;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1f5fa3;
  }
}

.header {
  background: rgb(13, 63, 100);
  background: linear-gradient(
    0deg,
    rgba(13, 63, 100, 1) 0%,
    rgba(0, 46, 72, 1) 100%
  );
}

.friend {
  &:last-child {
    margin-bottom: 0;
  }
}

.headerButton {
  transition: all 0.5s ease-out;

  &:hover,
  &:active,
  &.active {
    &:not([disabled]) {
      @apply bg-black-alpha-20;
    }
  }
}
