.bar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 21;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  flex-shrink: 0;
  background-color: #1b1f28;
  color: white;
}

.header {
  height: 52px;
  display: flex;
  padding-bottom: 0px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-stroke, #2a313c);
}

.profile {
  display: flex;
  height: 40px;
  padding: 2px 0px;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #393b41;
}

.name {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}

.exit {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.smallButtons {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-bottom: 1px solid var(--dark-stroke, #2a313c);
}

.smallButton {
  display: flex;
  padding: 6px 0px;
  align-items: center;
  gap: 8px;
  p {
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
}

.notificationsCount {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--additional-purple, #a81aff);
  p {
    color: var(--White, #fff);
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    letter-spacing: 0.24px;
  }
}

.bigButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.bigButtonsRow {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.bigButton {
  display: flex;
  width: 168px;
  height: 52px;
  padding: 4px 6px 4px 4px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--dark-stroke, #2a313c);
  background: var(--dark-light, #262f40);
}

.bigButtonIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
}
.bigButtonText {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.badge {
  position: absolute;
  right: -5px;
  top: -10px;
  z-index: 1;
}

.badgeText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-60%) translateX(-50%);
  z-index: 2;
  color: var(--white, #fff);
  text-align: right;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.24px;
}

.badgeTime {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-62%) translateX(-50%);
  z-index: 2;
  color: var(--white, #fff);
  text-align: right;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.24px;
}

.bottom {
  width: 100%;
  display: flex;
  padding-top: 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-top: 1px solid var(--dark-stroke, #2a313c);
}

.register {
  display: flex;
  height: 40px;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: var(--blue-main, #0068ff);
}

.logout {
  display: flex;
  height: 40px;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--dark-stroke, #2a313c);
  background: var(--dark-light, #262f40);
}
