/* purgecss start ignore */
:root {
  --reach-slider: 1;
}

[data-reach-slider-input] {
  padding: 20px;
}

[data-reach-slider-input][data-disabled] {
  opacity: 0.5;
}

[data-reach-slider-track] {
  border-radius: 2px;
  background: #f2f2f2;
}

[data-reach-slider-track][data-orientation="horizontal"] {
  width: 100%;
  height: inherit;
}

[data-reach-slider-track][data-orientation="vertical"] {
  width: inherit;
  height: 100%;
}

[data-reach-slider-handle] {
  width: 12px;
  height: 12px;
  border: 2px solid #1159a6;
  background: #fff;
  border-radius: 10px;
  z-index: 1;
  transform-origin: center;
}

[data-reach-slider-handle][aria-orientation="horizontal"] {
  top: 50%;
  transform: translateY(-50%);
}

[data-reach-slider-handle][aria-orientation="horizontal"]:focus {
  transform: translateY(-50%);
}

[data-reach-slider-handle][aria-orientation="vertical"] {
  left: 50%;
  transform: translateX(-50%);
}

[data-reach-slider-handle][aria-orientation="vertical"]:focus {
  transform: translateX(-50%);
}

[data-reach-slider-track-highlight] {
  border-radius: inherit;
  background: #1159a6;
}

[data-reach-slider-track-highlight][data-orientation="horizontal"] {
  height: 100%;
}

[data-reach-slider-track-highlight][data-orientation="vertical"] {
  width: 100%;
}

[data-reach-slider-marker] {
  width: 8px;
  height: 8px;
  background: inherit;
  border: 1px solid #fff;
  border-radius: 4px;
  transform-origin: center;
}

[data-reach-slider-marker][data-orientation="horizontal"] {
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-reach-slider-marker][data-orientation="vertical"] {
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-reach-slider-marker][data-state="at-value"],
[data-reach-slider-marker][data-state="under-value"] {
  background: #1159a6;
}
/* purgecss end ignore */
