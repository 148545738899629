.scrollable {
  scrollbar-color: #1f5fa3 #073959;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: #073959;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #073959;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1f5fa3;
  }
}

.active {
  @apply border-blue-800;
  background-color: #295994;
}

.inactive {
  @apply border-blue-900;
}
