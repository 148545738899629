.tournamentArea {
  background: #827894 url("./bg.jpg");
  background-size: cover;
}

.counter {
  width: 75px;
}

.footer {
  background: linear-gradient(
    180deg,
    #656a75,
    #43454f 51%,
    #34363d 52%,
    #0f0e10
  );
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
}
