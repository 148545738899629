.topbarItem {
  background: #1a212d;
  border: 1px solid #2a313c;
  min-width: 166px;
  height: 68px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  .text {
    font-family: "PoppinsMedium";
    font-size: 13px;
    line-height: 18px;
    color: white;
  }
}

.topbarItem:hover {
  background: #32435d;
}

.badge {
  position: absolute;
  right: 3px;
  top: -10px;
  z-index: 1;
}

.badgeText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-60%) translateX(-50%);
  z-index: 2;
  color: var(--white, #fff);
  text-align: right;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.24px;
}

.badgeTime {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-62%) translateX(-50%);
  z-index: 2;
  color: var(--white, #fff);
  text-align: right;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.24px;
}
