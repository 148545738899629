.container {
  display: flex;
  width: 1114px;
  flex-direction: column;
  position: relative;
  padding: 16px;
  justify-content: space-between;
  border: 1px solid #32435d;
  background-image: linear-gradient(
      180deg,
      rgba(41, 81, 130, 0.6) 0%,
      rgba(22, 44, 71, 0.6) 100%
    ),
    url("../../bg.jpg");

  box-shadow: 0px -64.462px 64.462px 0px rgba(20, 39, 62, 0.5) inset,
    0px 64.462px 64.462px 0px rgba(44, 86, 138, 0.1) inset,
    0px 130.667px 261.333px 0px rgba(7, 13, 21, 0.23);
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  border-radius: 12px;

  @media screen and (max-width: 1239px) {
    width: 60%;
  }

  @media screen and (max-width: 1000px) {
    width: 75%;
  }

  @media screen and (max-width: 833px) {
    width: 343px;
  }
}

.contentContainer {
  @media screen and (max-width: 833px) {
    overflow: scroll;
  }
}

.header {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  @media screen and (max-width: 833px) {
    flex-direction: column;
  }
}

.headerTitle {
  height: 54px;
  margin-bottom: 8px;
  color: var(--White, #fff);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
}

.paidRewards {
  display: flex;
  flex-direction: row;
  gap: 6px;

  @media screen and (max-width: 833px) {
    flex-direction: column;
  }
}

.moreThanOneLineRewards {
  grid-template-columns: repeat(6, 1fr);
}

.oneLineRewars {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.rewardConfigsContainer {
  margin-top: 24px;
  display: grid;
  gap: 16px 8px;
  place-items: center;

  @media screen and (max-width: 1239px) {
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
  }

  @media screen and (max-width: 833px) {
    grid-template-columns: repeat(1, 1fr);
    height: 350px;
    place-items: center;
  }
}

.configContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.configContainerTitle {
  width: 170px;
  height: 48px;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #316bd4;
  color: var(--White, #fff);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.completedConfigurationTitle {
  background: #316bd4;
  border: 2px solid #61ff9e;
}

.currentConfigurationTitle {
  background: #0068ff;
  border: 2px solid #b7e7ff;
}

.futureConfigurationTitle {
  background: #3889ff;
  border: 2px solid #3889ff;
}

.completedReward {
  width: 170px;
  height: 180px;
  border-radius: 8px;
  border: 2px solid #61ff9e;
  background: linear-gradient(180deg, #407ff1 0%, #1a3158 100%);

  img {
    border-radius: 8px;
  }
}

.rewardConfigurationContainer {
  position: relative;
  width: 170px;
  height: 180px;
  border-radius: 8px;
  background: linear-gradient(180deg, #0068ff 0%, #1a3158 100%);

  img {
    border-radius: 8px;
  }
}

.completedRewardBorder {
  border: 2px solid #61ff9e;
}

.currentRewardBorder {
  border: 2px solid #b7e7ff;
}

.futureRewardBorder {
  border: 2px solid #3889ff;
}

.configurationRewardsContainer {
  position: absolute;
  top: 12px;
  left: 12px;
}

.rewardItem {
  width: 110px;
}

.closeButton {
  display: flex;
  width: 260px;
  height: 52px;
  padding: 2px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #4499e1;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
