.scrollable {
  scrollbar-color: #1f5fa3 #073959;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: #073959;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #073959;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1f5fa3;
  }
}

.even {
  background-color: #455061;
}

.odd {
  background-color: #5d6675;
}
