.divider {
  &:after {
    content: " | ";
  }
}

.authenticated {
  background: #13151b;
}

.chatText {
  font-size: 30px;
  top: 660px;
  left: calc(50% + 680px);
  z-index: 10001;
}

.chatButton {
  top: 707px;
  left: calc(50% + 660px);
  z-index: 10001;
}
