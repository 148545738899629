:root {
  --background-color: #1459cc;
  --text-color-main: #cdeef8;
  --scroll-color: #60aae9;
  --input-text-color: #73beea;
  --wrapper-background-color: #196fff;
  --white: white;
  --header-text-color-main: #196fff;
  --active-button: #2b6ead;
  --chat-list-item-background: #196fff;
  --text-dark: white;
  --create-channel-color: #3b87d5;
  --create-channel-text-color: #88bfe8;
  --create-channel-button-color: #196fff;
  --create-channel-button-disable-color: #1050be;
  --error-color: #ff3333;
  --message-background: #f1f1f1;
  --message-text-color: #21327a;
  --my-message-background: #84dcf3;
  --my-message-text-color: #21327a;
  --unread-color: #fa4821;
  --online-status-color: #1fd84f;
  --main-pannel: white;
  --message-input-border-color: #196fff;
  --search-input-background-color: white;
  --create-channel-container-background: white;
  --no-chat-container-background: white;
  --no-friends-placeholder-text-color: #16419e;
  --active-tab-color: #67dbff;
  --chat-header-text: white;
}
.theme-dark {
  --background-color: #21327a;
  --text-color-main: #2794cd;
  --scroll-color: #60aae9;
  --input-text-color: #73beea;
  --wrapper-background-color: #152148;
  --white: white;
  --header-text-color-main: #83c1f5;
  --active-button: #2b6ead;
  --chat-list-item-background: #152148;
  --text-dark: white;
  --create-channel-color: #3b87d5;
  --create-channel-text-color: #88bfe8;
  --create-channel-button-color: #63b3e1;
  --create-channel-button-disable-color: #186693;
  --error-color: #ff3333;
  --message-background: #f1f1f1;
  --message-text-color: #21327a;
  --my-message-background: #84dcf3;
  --my-message-text-color: #21327a;
  --unread-color: #fa4821;
  --online-status-color: #1fd84f;
  --main-pannel: #152148;
  --message-input-border-color: #0e7acc;
  --search-input-background-color: #152148;
  --create-channel-container-background: #152148;
  --no-chat-container-background: #152148;
  --no-friends-placeholder-text-color: white;
  --active-tab-color: #60aae9;
  --chat-header-text: white;
}
.theme-light {
  --background-color: #84dcf3;
  --text-color-main: #2794cd;
  --scroll-color: #60aae9;
  --input-text-color: #73beea;
  --wrapper-background-color: #c3ecf7;
  --white: white;
  --header-text-color-main: #83c1f5;
  --active-button: #2b6ead;
  --chat-list-item-background: #dff2f8;
  --text-dark: #16419e;
  --create-channel-color: #3b87d5;
  --create-channel-text-color: #88bfe8;
  --create-channel-button-color: #63b3e1;
  --create-channel-button-disable-color: #186693;
  --error-color: #ff3333;
  --message-background: #cdeef8;
  --message-text-color: #1e87b1;
  --my-message-background: #84c1f6;
  --my-message-text-color: white;
  --unread-color: #fa4821;
  --online-status-color: #1fd84f;
  --main-pannel: white;
  --message-input-border-color: white;
  --search-input-background-color: white;
  --create-channel-container-background: white;
  --no-chat-container-background: white;
  --no-friends-placeholder-text-color: #16419e;
  --active-tab-color: #60aae9;
  --chat-header-text: #2b6ead;
}

$background-color: var(--background-color);
$scroll-color: var(--scroll-color);
$input-text-color: var(--input-text-color);
$wrapper-background-color: var(--wrapper-background-color);
$white: var(--white);
$header-text-color: var(--header-text-color-main);
$active-button: var(--active-button);
$chat-list-item-background: var(--chat-list-item-background);
$text-dark: var(--text-dark);
$create-channel-color: var(--create-channel-color);
$create-channel-text-color: var(--create-channel-text-color);
$create-channel-button-color: var(--create-channel-button-color);
$create-channel-button-disable-color: var(
  --create-channel-button-disable-color
);
$error-color: var(--error-color);
$message-background: var(--message-background);
$message-text-color: var(--message-text-color);
$my-message-background: var(--my-message-background);
$my-message-text-color: var(--my-message-text-color);
$unread-color: var(--unread-color);
$online-status-color: var(--online-status-color);
$main-pannel: var(--main-pannel);
$message-input-border-color: var(--message-input-border-color);
$search-input-background-color: var(--search-input-background-color);
$create-channel-container-background: var(
  --create-channel-container-background
);
$no-chat-container-background: var(--no-chat-container-background);
$no-friends-placeholder-text-color: var(--no-friends-placeholder-text-color);
$active-tab-color: var(--active-tab-color);
$text-color: var(--text-color-main);
$chat-header-text: var(--chat-header-text);

.hidden-chat {
  display: none;
}

.show-chat {
  display: block;
}

.custom-styles {
  max-width: 600px;
  width: 600px;
  z-index: 1000;
  max-height: 709px;
  height: 709px;
  right: 60px;
  position: absolute;
  border: 3px solid $white;
  border-radius: 18px;
  background-color: $background-color;
  font-family: "Calibri";

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $scroll-color;
  }

  .add-channel-container {
    background-color: $wrapper-background-color;
    display: flex;
    align-items: center;
    padding: 0px 15px 5px 15px;
    max-width: 225px;
    outline: none;
    .add-button {
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }
    .create-channel-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $text-color;
    }
    .add-channel-placeholder {
      height: 25px;
      width: 100%;
    }
  }
  .str-chat__channel-list {
    box-shadow: none;
    height: 100%;
  }
  .str-chat__input-flat .str-chat__input--emojipicker {
    left: -12px;
  }
  .message-input {
    background-color: $wrapper-background-color !important;
    padding: 7px 10px;
    border-radius: 20px;
  }
  .str-chat.messaging.dark {
    background: $background-color;
    border-radius: 18px;
    max-height: 663px;
    padding: 10px;
  }
  .str-chat__channel-list.str-chat__channel-list-react {
    border: none !important;
  }
  .str-chat-channel.messaging .str-chat__main-panel {
    background: $main-pannel;
    border-radius: 18px;
    padding: 8px 3px 6px 3px;
  }
  .dark.str-chat .str-chat__input-flat {
    border: 2px solid $message-input-border-color;
  }
  .dark.str-chat .str-chat__input-flat textarea {
    background: $wrapper-background-color;
    padding: 0px 0px 0px 45px;
    color: $input-text-color;
  }
  .dark.str-chat .str-chat__input-flat-vip textarea {
    background: $wrapper-background-color;
    padding: 0px 0px 0px 62px;
    color: $input-text-color;
  }

  .dark.str-chat .str-chat__input-flat textarea::placeholder {
    color: $input-text-color;
  }
  .str-chat__input-flat .str-chat__textarea > textarea {
    min-height: 16px;
  }
  .str-chat__input-flat .str-chat__textarea > textarea:focus {
    box-shadow: none;
  }
  .str-chat__message-simple-status {
    display: none;
  }
  .dark.str-chat .str-chat__message--me .str-chat__message-text-inner,
  .dark.str-chat .str-chat__message-simple--me .str-chat__message-text-inner {
    border-radius: 12px 0px 12px 12px;
    background-color: $my-message-background;
    padding: 10px 10px 12px 10px;
    color: $my-message-text-color;
  }
  .messaging.str-chat .str-chat__list {
    background: transparent;
    padding: 0px 4px 0px 6px;
  }
  .str-chat__input-flat-emojiselect {
    top: calc(100% - 18px);
    left: -2px;
  }
  .rfu-file-upload-button {
    left: 18px;
    top: calc(100% - 17px);
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .str-chat__input-flat .str-chat__textarea {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .send-gift-button {
    position: absolute;
    left: 19px;
    top: calc(100% - 20px);
  }
  .send-gift-button-vip {
    position: absolute;
    left: 36px;
    top: calc(100% - 20px);
  }
  .chat-header-container {
    background: $background-color;
    border-radius: 7px 7px 0px 0px;
    padding: 6px;
    height: 50px;
    margin: 0px 6px;
    .avatar-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 2px solid $scroll-color;
      margin-right: 12px;
    }
    .name-text {
      font-size: 18px;
      color: $text-dark;
      font-weight: 700;
    }
  }
  .chat-list-container {
    background-color: $background-color;
    width: 225px;
    border: none;
    height: 673px;
    .list-box {
      background-color: $wrapper-background-color;
      height: 532px;
      border-radius: 0px 0px 16px 16px;
      padding: 5px 3px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .chat-list-container-not-vip {
    background-color: $background-color;
    width: 225px;
    border: none;
    height: 673px;
    .list-box {
      background-color: $wrapper-background-color;
      height: 557px;
      border-radius: 0px 0px 16px 16px;
      padding: 5px 3px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .chat-list-item-container {
    width: 100%;
    background-color: $chat-list-item-background;
    border: 2px solid $active-tab-color;
    padding: 7px 5px;
    display: flex;
    margin-bottom: 4px;
    position: relative;
    .time-message-text {
      width: 28px;
      position: absolute;
      top: 4px;
      right: 7px;
      color: $text-dark;
      line-height: 12px;
      font-size: 10px;
      font-weight: 400;
    }
    .str-chat__channel-preview-messenger--right {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .list-item-title {
      color: $text-dark;
      font-size: 14px;
      font-weight: 700;
      height: 21px;
    }
    .list-item-overflow {
      width: 120px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: start;
    }
    .latest-message,
    .latest-message > p {
      color: $text-dark;
      font-size: 14px;
      font-weight: 400;
      text-align: start;
      width: 120px;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .str-chat__channel-preview-unread-badge {
      width: 32px;
      height: 17px;
      background-color: $scroll-color;
      position: absolute;
      top: 18px;
      right: 5px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .unread-count {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
      }
    }
  }
  .str-chat__avatar {
    flex-basis: 32px !important;
    width: 32px !important;
    height: 32px !important;
    cursor: pointer;
  }
  .str-chat__avatar--circle {
    border-radius: 0px;
  }
  .active-channel {
    background-color: $background-color;
  }
  .chat-list-item-avatar-container {
    margin-right: 10px;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    padding: 6px 4px 4px 10px;
    .header-text {
      color: $chat-header-text;
      font-size: 20px;
      font-weight: 700;
      margin-left: 10px;
    }
  }

  .create-channel-container {
    width: calc(100% - 245px);
    height: 663px;
    border-radius: 18px;
    margin-left: 245px;
    background-color: $background-color;
    padding: 10px 10px 10px 0px;
    .create-channel-form-container {
      background-color: $create-channel-container-background;
      width: 100%;
      height: 100%;
      border-radius: 18px;
      padding: 8px 10px 10px 10px;
      .create-channel-header {
        background-color: $background-color;
        width: 100%;
        height: 50px;
        border-radius: 7px 7px 0px 0px;
        padding: 14px 0px 14px 16px;
        display: flex;
        align-items: center;
        margin-bottom: 104px;
        .back-button {
          margin-right: 22px;
          width: 16px;
          height: 16px;
        }
        .header-text {
          color: $text-dark;
          line-height: 22px;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .create-channel-send-message-header {
        background-color: $background-color;
        width: 100%;
        height: 50px;
        border-radius: 7px 7px 0px 0px;
        padding: 14px 0px 14px 16px;
        display: flex;
        align-items: center;
        .back-button {
          margin-right: 22px;
          width: 16px;
          height: 16px;
        }
        .header-text {
          color: $text-dark;
          line-height: 22px;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .create-channel-add-photo {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 90px;
        .photo-size {
          width: 195px;
          height: 195px;
        }
        .add-photo-button {
          margin-bottom: 8px;
        }
        .add-photo-text {
          color: $create-channel-text-color;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .create-channel-input-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
        .create-channel-input {
          width: 280px;
          border-bottom: 1px solid $create-channel-color;
          color: $create-channel-text-color;
          background-color: $create-channel-container-background;
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          text-align: center;
        }
        .create-channel-input::placeholder {
          color: $create-channel-text-color;
          background-color: $create-channel-container-background;
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
  .no-friends-placeholder {
    margin-top: 123px;
    color: $no-friends-placeholder-text-color;
    height: 513px;
  }
  .chat-friends-list__container {
    height: 500px;
    overflow-y: auto;
  }
  .chat-friends-list__load-more-container {
    background: $scroll-color;
    width: 29px;
    height: 15px;
    border-radius: 15px;
    align-self: center;
    margin: auto;
    margin-top: 4px;
  }
  .chat-friends-list__load-more-image {
    margin: auto;
  }
  .chat-friends-list__friend-name {
    display: flex;
    color: $text-dark;
    align-items: center;
    width: 75%;
    font-size: 14px;
    font-family: "Calibri";
  }
  .chat-friends-list__create-chat-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .chat-friends__search-container {
    padding: 5px 3px 7px 3px;
    color: $text-dark;
    background-color: $background-color;
    .search-input {
      padding-left: 30px;
      margin: auto;
      width: 99%;
      border-radius: 12px;
      color: $input-text-color;
    }
    .search-input::placeholder {
      color: $input-text-color;
    }
    .search-icon {
      position: absolute;
      top: 12px;
      left: 12px;
    }
    .close-icon {
      position: absolute;
      top: 13px;
      right: 12px;
    }
  }

  .direct-chat_create-container {
    padding: 5px 3px 0px 3px;
    margin-top: 18px;
    margin-bottom: 22px;
    color: $text-dark;
    .search-input {
      border: 2px solid $background-color;
      margin: auto;
      width: 99%;
      border-radius: 12px;
      background-color: $search-input-background-color;
      color: $input-text-color;
    }
    .search-input-active {
      border: 2px solid $background-color;
      margin: auto;
      width: 99%;
      border-radius: 12px;
      background-color: $search-input-background-color;
      color: $input-text-color;
    }
    .search-input::placeholder {
      color: $input-text-color;
      background-color: $search-input-background-color;
    }
    .search-icon {
      position: absolute;
      top: 12px;
      left: 12px;
    }
    .close-icon {
      position: absolute;
      top: 13px;
      right: 12px;
    }
  }
  .margin-top {
    margin-top: 68px;
  }
  .create-channel-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .create-channel-button {
      padding: 8px 90px;
      background-color: $create-channel-button-color;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button-text {
      color: $text-dark;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
    .create-channel-button-disabled {
      padding: 8px 90px;
      background-color: $create-channel-button-disable-color;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .error-creating-channel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .error-text {
      color: $error-color;
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .str-chat__message-simple--me .str-chat__message-attachment {
    --attachment-max-width: none;
  }
  .str-chat__message-attachment,
  .str-chat__message-simple-attachment {
    --attachment-max-width: none;
  }
  .report-button {
    position: absolute;
    display: none;
    top: 2px;
    right: -20px;
    z-index: 100;
  }
  .dark.str-chat .str-chat__message-text-inner,
  .dark.str-chat .str-chat__message-simple-text-inner {
    border-radius: 0px 12px 12px 12px;
    background-color: $message-background;
    color: $message-text-color;
    padding: 10px 10px 12px 10px;
  }
  .str-chat__message:hover .report-button {
    display: block;
  }
  .str-chat__li {
    position: unset;
  }
  .str-chat__list {
    position: unset;
  }

  .str-chat__list-notifications {
    padding: 0px;
    margin-bottom: 6px;
    .str-chat__custom-notification.notification-success {
      height: 30px;
      padding: 6px 23px;
      background-color: $message-text-color;
      border: none;
      border-radius: 10px;
      color: white;
    }
  }
  .search-container {
    padding: 5px 3px 7px 3px;
    color: $text-dark;
    background-color: $wrapper-background-color;
    border-radius: 2px solid $background-color;
    border-radius: 16px 16px 0px 0px;
    // border: 3px solid $message-input-border-color;
    .search-input {
      border: 3px solid $message-input-border-color;
      padding-left: 30px;
      width: 219px;
      border-radius: 12px;
      background-color: $search-input-background-color;
      color: $input-text-color;
    }
    .search-input::placeholder {
      color: $input-text-color;
    }
    .search-icon {
      position: absolute;
      top: 12px;
      left: 12px;
    }
    .close-icon {
      position: absolute;
      top: 14px;
      right: 12px;
    }
  }
  .nav-bar {
    background-color: $wrapper-background-color;
    width: 225px;
    border: none;
    .unreadMessage {
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 50%;
      background-color: $unread-color;
      width: 8px;
      height: 8px;
    }
    .direct-private-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-top: 5px;
      .button-style {
        justify-self: stretch;
        height: 30px;
        padding: 0px 4px 3px 4px;
        font-size: 16px;
        color: $text-color;
      }
      .active-button {
        border-bottom: 5px solid $active-tab-color;
      }
    }
  }
  .str-chat__loading-channels {
    max-width: 225px;
    background-color: $wrapper-background-color;
    border-radius: 0px 0px 16px 16px;
  }
  .online-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $online-status-color;
    position: absolute;
    top: 40px;
    left: 40px;
  }
  .no-chat-container {
    background-color: $no-chat-container-background;
    border-radius: 18px;
    padding: 0px 24px;
    margin-top: 10px;
    width: calc(100% - 255px);
    height: 643px;
    border-radius: 18px;
    margin-left: 245px;
    .header-text {
      line-height: 24px;
      font-size: 20px;
      color: $header-text-color;
      font-weight: 700;
    }
    .regular-text {
      line-height: 20px;
      font-size: 16px;
      color: $header-text-color;
      font-weight: 400;
    }
  }
  .channel-skeleton-container {
    background-color: $no-chat-container-background;
    border-radius: 18px;
    padding: 8px 10px 6px 10px;
    margin-top: 10px;
    width: calc(100% - 255px);
    height: 643px;
    border-radius: 18px;
    margin-left: 245px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .skeleton-header {
      height: 50px;
      background-color: $background-color;
      width: 100%;
      border-radius: 7px 7px 0px 0px;
    }
    .input-skeleton {
      border-radius: 20px;
      background-color: $wrapper-background-color;
      height: 32px;
      width: 100%;
    }
  }
  .search-no-results-image {
    width: 140px;
    height: 120px;
  }
}

@media (max-width: 960px) {
  .str-chat-channel-list.messaging,
  .str-chat-channel-list.team,
  .str-chat-channel-list.commerce {
    position: unset;
    min-height: 400px;
  }
  .messaging.str-chat .str-chat__list-notifications,
  .messaging.str-chat .str-chat__list .str-chat__jump-to-latest-message {
    background: transparent;
    padding: 0px 4px 0px 6px;
  }
}
