.spinner {
  border: 3px solid orangered;
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  width: 25px;
  height: 25px;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.playContainer {
  position: absolute;
  width: 162px;
  height: 29px;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  border: 2px solid black;
  border-radius: 10px;
  background-color: white;
}
