.mainContainer {
  height: 100vh;
  width: 100vw;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;

  @media (min-width: 701px) and (max-width: 1200px) {
    padding: 0;
  }
  @media screen and (max-width: 834px) {
    height: 100%;
    padding: 0px;
    display: block;
  }
}

.loginContainer {
  background-image: url("./assets/Bg.jpg");
  background-position: center;
  background-size: cover;
  overflow: hidden;
  padding: 50px 50px 0px 50px;
  height: 619px;
  @media (min-width: 701px) and (max-width: 834px) {
    height: calc(100% - 148px);
    padding: 60px 20px 0px 0px;
  }
  @media (min-width: 375px) and (max-width: 700px) {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100% - 70px);
    padding: 0px;
  }
  @media screen and (max-width: 375px) {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100% - 52px);
    padding: 0px;
  }
}

.girlContainer {
  background-image: url("./assets/girl.png");
  background-position: bottom 0px right 0px;
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: 700px) and (max-width: 834px) {
    background-image: url("./assets/largeGirl.png");
    background-position: bottom 0px right 0px;
    background-size: contain;
  }
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.loginSelectButton {
  background-color: #13151b;
  border-color: #0068ff;
  border-radius: 8px;
}
.loginSelectButton:hover {
  background-color: #0068ff;
}

.loginForm {
  width: 50%;
  @media screen and (max-width: 834px) {
    overflow-y: auto;
  }
  @media (min-width: 375px) and (max-width: 700px) {
    padding-top: 60px;
    width: 100%;
  }
  @media screen and (max-width: 375px) {
    padding-top: 30px;
    width: 100%;
  }
}

.socialLinks {
  font-size: 1.2rem;
}
.bottomText {
  padding-bottom: 20px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: calc(100% - 20px);
    max-width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 20px;
    padding-bottom: 40px;
  }
  @media (min-width: 375px) and (max-width: 700px) {
    width: 100%;
    height: 675px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 30px;
    background: linear-gradient(
      180deg,
      rgba(4, 17, 49, 0) 0%,
      #041131 100%,
      #041131 100%
    );
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    height: 435px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 16px 30px 16px;
    background: linear-gradient(
      180deg,
      rgba(4, 17, 49, 0) 0%,
      #041131 100%,
      #041131 100%
    );
  }
}

.bottomGirl {
  @media (min-width: 375px) and (max-width: 700px) {
    display: flex;
    align-items: flex-end;
    height: 100%;
    background-image: url("./assets/largeGirl.png");
    background-position: 40% 0%;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
  }
  @media screen and (max-width: 375px) {
    display: flex;
    align-items: flex-end;
    height: 100%;
    background-image: url("./assets/smallGirl.png");
    background-position: 40% 0%;
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
  }
}

.midScreenFooter {
  display: none;
  width: 100%;
  height: 78px;
  background-color: #1b1f28;
  color: white;
  @media (min-width: 700px) and (max-width: 834px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  .smallFooterHeader {
    height: 38px;
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .footerLinks {
      padding: 0px 12px;
      font-size: 14px;
      font-family: "PoppinsRegular";
      font-weight: 400;
    }
  }
  .separator {
    border-right: 1px solid white;
    height: 9px;
  }
  .cardsImg {
    padding-bottom: 24px;
    @media screen and (max-width: 375px) {
      padding-bottom: 12px;
    }
  }
}

.smallFooter {
  display: none;
  width: 100%;
  height: 78px;
  background-color: #1b1f28;
  color: white;
  @media (min-width: 375px) and (max-width: 700px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  @media screen and (max-width: 375px) {
    height: 66px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  .smallFooterHeader {
    height: 38px;
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .footerLinks {
      padding: 0px 12px;
      font-size: 14px;
      font-family: "PoppinsRegular";
      font-weight: 400;
    }
    .hideLink {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }
  .separator {
    border-right: 1px solid white;
    height: 9px;
  }
  .cardsImg {
    padding-bottom: 24px;
    @media screen and (max-width: 375px) {
      padding-bottom: 12px;
    }
  }
}

.footerHeader {
  padding: 25px 40px;
  background-color: #444;
  z-index: 1;
  align-items: center;
  width: 100%;
  margin: 0;
  display: flex;

  @media screen and (max-width: 834px) {
    display: none;
  }
}

.footer {
  padding: 15px 0;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  @media screen and (max-width: 834px) {
    display: none;
  }
}

.linksHeader {
  a {
    cursor: pointer;
    margin: 0 10px;
    transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      opacity: 0.6;
    }

    @media (min-width: 700px) and (max-width: 1024px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 900px) {
      font-size: 0.75rem;
    }
  }
}

.chooseLoginText {
  font-family: "PoppinsSemiBold";
  font-size: 16px;
  line-height: 22px;
}

.chooseLoginButton {
  width: 360px;
  @media screen and (max-width: 834px) {
    width: calc(100% - 40px);
    max-width: 360px;
  }
}

.backToMainButton {
  @media screen and (max-width: 834px) {
    display: none;
  }
}

.loginInput {
  position: relative;
  border: 2px solid #32435d;
  width: 360px;
  border-radius: 8px;
  z-index: 10;
  height: 58px;
  color: #607da9;
  margin-top: 21px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: 100%;
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    max-width: 360px;
  }
  legend {
    z-index: 10;
    font-weight: 600;
    font-family: "PoppinsSemiBold";
    font-size: 14px;
    line-height: 20px;
    display: none;
  }
  input {
    top: 0px;
  }
  input::placeholder {
    color: white;
    font-family: "PoppinsSemiBold";
    font-weight: 600;
    font-size: 16;
  }
  button {
    position: absolute;
    z-index: 15;
    top: 15px;
    right: 16px;
    width: 24px;
    height: 24px;
  }
}
.loginInput:has(input:focus) {
  position: relative;
  border: 2px solid #3889ff;
  width: 360px;
  border-radius: 8px;
  z-index: 10;
  height: 67px;
  color: #3889ff;
  margin-top: 12px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: 100%;
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    max-width: 360px;
  }
  legend {
    z-index: 10;
    font-weight: 600;
    font-family: "PoppinsSemiBold";
    font-size: 14px;
    line-height: 20px;
    display: block;
  }
  input {
    top: -9px;
  }
  input:focus::placeholder {
    color: transparent;
  }
  button {
    position: absolute;
    z-index: 15;
    top: 6px;
    right: 16px;
    width: 24px;
    height: 24px;
  }
}

.fullfiledInput {
  position: relative;
  border: 2px solid #32435d;
  width: 360px;
  border-radius: 8px;
  z-index: 10;
  height: 67px;
  color: #607da9;
  margin-top: 12px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: 100%;
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    max-width: 360px;
  }
  legend {
    z-index: 10;
    font-weight: 600;
    font-family: "PoppinsSemiBold";
    font-size: 400;
    display: block;
  }
  input {
    top: -9px;
  }
  button {
    top: 6px;
  }
}
.textInput {
  width: 100%;
  border-radius: 8px;
  padding-left: 16px;
  z-index: 1;
  height: 54px;
  background-color: #13151b;
  color: white;
}

.errorColor {
  color: #ff1805;
  border-color: #ff1805;
}

.errorMessage {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #ff1805;
  font-weight: 400;
  font-family: "PoppinsRegular";
  margin-top: 6px;
  max-width: 360px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: calc(100% - 40px);
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: calc(100% - 32px);
    max-width: 360px;
  }
}

.loginButton {
  width: 360px;
  height: 50px;
  background-color: #0068ff;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  font-family: "PoppinsSemiBold";
  font-size: 16px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: calc(100% - 40px);
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: calc(100% - 32px);
    max-width: 360px;
  }
}

.forgotPassword {
  padding-right: 20px;
  @media (min-width: 701px) and (max-width: 834px) {
    padding-right: 10px;
  }
  @media screen and (max-width: 375px) {
    padding-right: 10px;
  }
}
.dontHavePassword {
  padding-left: 20px;
  @media (min-width: 701px) and (max-width: 834px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 375px) {
    padding-left: 10px;
  }
}

.backToMainScreen {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: "PoppinsMedium";
}

.emailLoginLinkText {
  color: #3889ff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  font-family: "PoppinsBold";
  cursor: pointer;
  text-align: center;
}
.emailLoginLinkSeparator {
  border-right: 1px solid #32435d;
  height: 17px;
}
.emailLoginHeader {
  width: 360px;
  font-weight: 600;
  font-family: "PoppinsSemiBold";
  font-size: 36px;
  line-height: 50px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: calc(100% - 40px);
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: calc(100% - 32px);
    max-width: 360px;
  }
}

.centerLogo {
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: #1b1f28;
  height: 70px;
  padding: 0px 16px;
  @media screen and (max-width: 834px) {
    display: flex;
  }
  @media screen and (max-width: 375px) {
    height: 52px;
  }
  .logoImage {
    @media screen and (max-width: 325px) {
      width: 100px;
    }
  }
}
.logoImg {
  @media screen and (max-width: 834px) {
    display: none;
  }
}
.emailLoginSubHeader {
  width: 360px;
  font-weight: 600;
  font-family: "PoppinsSemiBold";
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 7px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: calc(100% - 40px);
    max-width: 360px;
    margin-bottom: 11px;
  }
  @media screen and (max-width: 375px) {
    width: calc(100% - 32px);
    margin-bottom: 7px;
  }
}
.emailLoginLinks {
  width: 360px;
  margin-top: 28px;
  margin-bottom: 28px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: calc(100% - 40px);
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: calc(100% - 32px);
  }
}
.emailHaveNoPasswordSubHeader {
  width: 360px;
  font-style: normal;
  font-weight: 500;
  font-family: "PoppinsMedium";
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 7px;
  @media (min-width: 701px) and (max-width: 834px) {
    width: calc(100% - 40px);
    margin-bottom: 11px;
    max-width: 360px;
  }
  @media screen and (max-width: 375px) {
    width: calc(100% - 40px);
    margin-bottom: 7px;
    max-width: 360px;
  }
}
.passwordWithTooltipContainer {
  display: flex;
  @media (min-width: 375px) and (max-width: 834px) {
    display: block;
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 375px) {
    display: block;
    width: calc(100% - 32px);
  }
}
.tooltipContainer {
  position: relative;
  @media screen and (max-width: 834px) {
    display: none;
  }
}
.inputWrapper {
  width: 360px;
  @media (min-width: 701px) and (max-width: 834px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  @media screen and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
.tooltipInstructionTriangle {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: 10;
  margin-top: 13px;
  right: -1px;
  width: 18px;
  height: 8px;
}
.tooltipInstruction {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: 10;
  width: 272px;
  height: auto;
  right: -232px;
  bottom: 65px;
  transform: translateX(-60%);
  background-color: #32435d;
  color: white;
  flex-direction: column;
  align-items: left;
  transition: opacity 0.3s ease-in-out;
  padding: 10px;
  border-radius: 4px;
  @media screen and (max-width: 1000px) {
    transform: translateX(-80%);
  }
  @media (min-width: 1000px) and (max-width: 1300px) {
    transform: translateX(-65%);
  }
}
.tooltipInstruction p {
  font-size: 18px;
  margin: 0;
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-family: "PoppinsMedium";
  font-size: 12px;
  line-height: 150%;
}
.tooltipInstruction p::before {
  content: "\2022"; /* Unicode character for bullet (dot) */
  position: absolute;
  left: 10px;
}
.tooltip {
  margin-top: 21px;
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
.tooltipContainer:hover .tooltipInstructionTriangle,
.tooltipContainer:hover .tooltipInstruction {
  display: flex;
  opacity: 1;
}

.resendCodeText {
  margin-top: 28px;
  color: white;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  font-family: "PoppinsMedium";
  text-align: center;
  @media (min-width: 700px) and (max-width: 834px) {
    width: calc(100% - 32px);
  }
}

/* Change the white background color for autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1a212d inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
