/* purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* purgecss end ignore */

*:focus {
  outline: none !important;
}

:root {
  --reach-dialog: 1;
  --reach-tabs: 1;
  --spinner-delay: 0;
}

@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

body {
  background: #121315 url("./bg.jpg");
}

#root {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.8631827731092436) 100%
  );
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pulse-animate {
  animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
