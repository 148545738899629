.balance {
  background: #262f40;
  border: 1px solid #2a313c;
  margin-right: 6px;
  border-radius: 100px;
  height: 36px;
  padding: 8px 0px 8px 18px;
}
.balance:hover {
  background: #32435d;
}

.balanceText {
  color: white;
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
}
