.borderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);

  width: calc(100% - 60px);
  height: calc(100% - 90px);
  border-radius: 12px;
  box-shadow: 0px 0px 50px 50px #121315;
  @media (min-width: 767px) and (max-width: 1200px) {
    width: calc(100% - 64px);
    height: calc(100vh - 64px);
    top: 32px;
    left: 32px;
    transform: translate(0%, 0%);
  }

  @media screen and (max-width: 767px) {
    width: calc(100% - 32px);
    height: calc(100vh - 32px);
    top: 16px;
    left: 16px;
    transform: translate(0%, 0%);
  }
}

.absoluteCentered {
  @media screen and (max-width: 767px) {
    align-items: start;
    margin-top: 40px;
  }
}

.fontStyle {
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 26px;
    font-style: normal;
    font-weight: 700;
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-image: linear-gradient(
      180deg,
      rgba(41, 81, 130, 0.6) 0%,
      rgba(22, 44, 71, 0.6) 100%
    ),
    url("../../bg.jpg");
  border: 3px solid #2084ff;

  box-shadow: 0px -64.462px 64.462px 0px rgba(20, 39, 62, 0.5) inset,
    0px 64.462px 64.462px 0px rgba(44, 86, 138, 0.1) inset,
    0px 130.667px 261.333px 0px rgba(7, 13, 21, 0.23);
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: x-large;
  border-radius: 12px;
}

.containerColor {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: space-between;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: rgba(27, 53, 86, 0.6);
}

.bannersWrapper {
  position: absolute;
  left: 56px;
  top: -16px;
  display: flex;
  gap: 8px;
  @media screen and (max-width: 767px) {
    left: 0px;
    top: -14px;
    gap: 0px;
  }
}

.scrollableModalContent {
  height: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  padding: 0 40px;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
}
.scrollableModalContent::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tableScrollHidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.tableScrollHidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.banner {
  position: relative;
  cursor: pointer;

  img {
    width: fit-content;
    min-width: 155px;
    height: 64px;
    @media screen and (max-width: 767px) {
      min-width: 135px;
      height: 54px;
    }
  }

  p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.closeIcon {
  border-radius: 12px 12px 0px 0px;
  padding: 20px 20px 0px 0px;
  @media screen and (max-width: 767px) {
    padding: 10px 10px 0px 0px;
    background: linear-gradient(
      180deg,
      #1a314d 5.36%,
      rgba(26, 49, 77, 0) 105.36%
    );
    padding-right: 0px;
    padding: 0px;
  }
}

.darkerImg {
  filter: brightness(65%);
}

.informationTitle {
  color: yellow;
  font-size: xx-large;
  font-weight: bold;
  padding: 24px 0 8px;
  @media screen and (max-width: 1200px) {
    padding: 0px 0px 12px 0px;
    font-size: 22px;
    line-height: 32px;
  }
}

.informationTitleLine {
  width: 100%;
  height: 2px;
  background: #a8b7d8;
  margin: 0 0 36px 0;
}

.userRightsTitle {
  color: yellow;
  font-size: xx-large;
  padding: 0 0 24px;
  @media screen and (max-width: 1200px) {
    padding: 0px 0px 12px 0px;
    font-size: 22px;
    line-height: 32px;
  }
}

.tableTabWrapper {
  padding-top: 12px;
  @media screen and (max-width: 767px) {
    padding: 0px 0px 12px 0px;
    font-size: 22px;
    line-height: 32px;
  }
}

.borderJoinButton {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  min-height: 80px;
  height: fit-content;
  padding: 2px;
  background: linear-gradient(red, yellow);
  border-radius: 12px;
  background: linear-gradient(to right, #2084ff 0%, #0e233c 50%, #2084ff 100%);
}

.joinButton {
  min-width: fit-content;
  min-height: 78px;
  height: fit-content;
  padding: 12px;
  font-size: xx-large;
  border-radius: 10px;
  background: linear-gradient(270deg, #30598c 99.84%, #274972 -0.16%);
  box-shadow: 3px -4px 6px 0px #1d2e47 inset,
    0px 5.408px 5.408px 0px #315b90 inset,
    0px 20.8px 41.6px 0px rgba(9, 16, 26, 0.16);

  color: #fff;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.24px;
  @media screen and (max-width: 1200px) {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    padding: 20px 50px;
  }
}

.vipIcon {
  margin-top: 40px;
  position: absolute;
  bottom: -16px;
  right: -16px;
  width: 120px;
  height: 120px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.vipIconWithText {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    margin-top: 0px;
    bottom: 0px;
    right: 0px;
  }
}
