.spinner {
  border: 3px solid orangered;
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  width: 25px;
  height: 25px;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.arrow {
  position: absolute;
  width: 50px;
  height: 100px;
  top: 260px;
  cursor: pointer;
  background-repeat: no-repeat;
}

.leaderboardHeder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderBoardContainer {
  position: absolute;
  top: 110px;
  width: 880px;
  border-radius: 5px;
  background-color: white;
}
.leaderboardProperties {
  color: black;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 880px;
  top: 70px;
  left: 0px;
  padding-right: 16px;
}

.leaderboardHeaderText {
  font-size: 45px;
  font-family: Bradley Hand;
  font-weight: bold;
  padding: 5px;
  color: #505050;
}

.leaderboardText {
  text-align: center;
  justify-content: center;
  font-size: 28px;
  font-family: Bradley Hand;
  font-weight: normal;
  padding: 5px;
  background-color: white;
}

.rewardText {
  text-align: center;
  justify-content: center;
  font-size: 24px;
  line-height: 24px;
  font-family: Bradley Hand;
  font-weight: normal;
  padding: 4px;
}

.moreInfoContainer {
  width: 570px;
  height: 338px;
  position: absolute;
  left: 525px;
  top: 330px;
  padding: 5px;
  border: 4px solid black;
  border-radius: 16px;
  background-color: white;
}

.recordContainer {
  color: black;
  display: flex;
  justify-content: space-between;
}

.valuesStyle {
  color: black;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  width: 864px;
  top: 70px;
  left: 0px;
  padding-right: 2px;
}

.leaderboardList {
  overflow-y: scroll;
  height: 370px;
}

.leaderboardList::-webkit-scrollbar {
  width: 16px;
  height: 80px;
}

.leaderboardList::-webkit-scrollbar-button:vertical:start:decrement {
  display: block;
  background: url("./assets/triangle_up.png") no-repeat;
}

.leaderboardList::-webkit-scrollbar-button:vertical:end:increment {
  display: block;
  background: url("./assets/triangle_down.png") no-repeat;
}
.leaderboardList::-webkit-scrollbar-track {
  background: url("./assets/scrollbarTrack.png") no-repeat;
  margin-top: 3px;
  margin-bottom: 3px;
}
.leaderboardList::-webkit-scrollbar-thumb {
  border: 3px solid black;
  background-color: white;
  border-radius: 6px;
  background-size: cover;
}

.flagIcon {
  display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
